import "core-js/modules/es.number.constructor.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-card", {
    staticClass: "details",
    attrs: {
      bordered: false
    }
  }, [_c("span", {
    staticClass: "box"
  }, [_c("span", {
    staticClass: "item"
  }, [_c("span", {
    staticClass: "it_title"
  }, [_vm._v("鉴定师：")]), _c("span", {
    staticClass: "it_value"
  }, [_vm._v(_vm._s(_vm.filterRecordValue(_vm.nickname)))])]), _c("span", {
    staticClass: "item"
  }, [_c("span", {
    staticClass: "it_title"
  }, [_vm._v("完成数量：")]), _c("span", {
    staticClass: "it_value"
  }, [_vm._v(_vm._s(_vm.filterRecordValue(_vm.checkcount)))])]), _c("span", {
    staticClass: "item"
  }, [_c("span", {
    staticClass: "it_title"
  }, [_vm._v("收入：")]), _c("span", {
    staticClass: "it_value"
  }, [_vm._v(_vm._s(_vm.filterRecordValue(_vm.checkerincome)))])]), _c("span", {
    staticClass: "item"
  }, [_c("span", {
    staticClass: "it_title"
  }, [_vm._v("账单时间：")]), _c("span", {
    staticClass: "it_value"
  }, [_vm._v(_vm._s(_vm.begintime + "——" + _vm.endtime))])]), _c("span", {
    staticClass: "item"
  }, [_c("span", {
    staticClass: "it_title"
  }, [_vm._v("关闭数量：")]), _c("span", {
    staticClass: "it_value"
  }, [_vm._v(_vm._s(_vm.filterRecordValue(_vm.nocheckcount)))])]), _c("span", {
    staticClass: "item"
  }, [_c("span", {
    staticClass: "it_title"
  }, [_vm._v("扣除：")]), _c("span", {
    staticClass: "it_value"
  }, [_vm._v(_vm._s(_vm.filterRecordValue(_vm.deductincome)))])]), _c("span", {
    staticClass: "item"
  }, [_c("span", {
    staticClass: "it_title"
  }, [_vm._v("最终收入：")]), _c("span", {
    staticClass: "it_value"
  }, [_vm._v(_vm._s(Number(_vm.checkerincome) + Number(_vm.deductincome)))])])])]), _c("a-card", {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      bordered: false
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.createForm,
      layout: "inline"
    }
  }, [_c("FormItems", {
    attrs: {
      searchKeys: _vm.detailsearchKeys
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn() {
        return [_c("a-button", {
          attrs: {
            type: "primary",
            size: "large"
          },
          on: {
            click: _vm.handleSearch
          }
        }, [_vm._v("查询")]), _c("a-button", {
          staticStyle: {
            "margin-left": "20px",
            border: "0",
            background: "#f5f5f5"
          },
          attrs: {
            size: "large"
          },
          on: {
            click: _vm.handleReset
          }
        }, [_vm._v("重置 ")])];
      },
      proxy: true
    }])
  })], 1), _c("a-table", {
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.columns,
      rowKey: function rowKey(it, i) {
        return i;
      },
      loading: _vm.loading
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (_ref) {
      var key = _ref.key;
      return {
        key: key,
        fn: function fn(text, record, index) {
          return [key == "status" ? _c("span", [_c("span", [_vm._v(_vm._s(_vm.statusText(record.status)))])]) : key == "type" ? _c("span", [_c("span", [_vm._v(_vm._s(_vm.typeText(record.type)))])]) : _c("span", [_vm._v(_vm._s(_vm.filterRecordValue(record[key])))])];
        }
      };
    })], null, true)
  }), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      size: _vm.size,
      onPageSizeChange: _vm.onPageSizeChange,
      onShowSizeChange: _vm.onShowSizeChange
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };